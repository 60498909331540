<template>
    <v-app-bar elevation="2" color="white">
        <!-- <v-img
            max-width="188"
            :src="`/img/${getLogos.appBar}`"
            contain
        ></v-img> -->
        <img
            :src="`/img/${getLogos.appBar}`"
            alt="Logotipo da empresa"
            style="max-width: 188px;"
        >

        <v-spacer></v-spacer>

        <v-tooltip bottom v-if="checkUrl()">
            <template v-slot:activator="{ on }">
                <v-btn icon class="mr-2"
                    v-on="on"
                    color="primary"
                    @click="$emit('setMeetingRoomsDialog', true)"
                >
                    <v-icon color="primary">mdi-video</v-icon>
                </v-btn>
            </template>
            <span>Salas de reunião</span>
        </v-tooltip>

        <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
                <v-btn icon class="mr-2"
                    v-on="on"
                    color="primary"
                    @click="$router.push('modules')"
                >
                    <v-icon color="primary">mdi-home</v-icon>
                </v-btn>
            </template>
            <span>Home</span>
        </v-tooltip>

        <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn icon
                    v-on="on"
                    @click="copyUrl()"
                    color="primary"
                    class="mr-2"
                >
                    <v-icon color="primary">mdi-share-variant</v-icon>
                </v-btn>
            </template>
            <span>Compartilhar</span>
        </v-tooltip> -->

        <v-menu
            bottom
            offset-y
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4"
                >
                    <v-badge
                        avatar
                        bottom
                        :color="sessionBadgeColor"
                        offset-x="18"
                        offset-y="18"
                    >
                        <v-avatar v-if="getUser.photo">
                            <v-img :src="getUser.photo"
                                :aspect-ratio="1/1"
                            >
                                <template v-slot:placeholder>
                                    <v-skeleton-loader
                                        type="avatar"
                                    ></v-skeleton-loader>
                                </template>
                            </v-img>
                        </v-avatar>
                        <v-avatar v-else>
                            <v-icon color="primary" class="text-h3">mdi-account-circle</v-icon>
                        </v-avatar>
                    </v-badge>
                </v-btn>
            </template>

            <v-sheet max-width="350px">
                <v-list>
                    <v-list-item-group>
                        <v-list-item @click="$router.push('user-profile')">
                            <v-list-item-icon class="mr-4">
                                <v-avatar v-if="getUser.photo">
                                    <v-img :src="getUser.photo"
                                        :alt="'Foto de ' + getUser.name"
                                        :aspect-ratio="1/1"
                                    />
                                </v-avatar>
                                <v-icon v-else color="primary" x-large>mdi-account-circle</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title class="text-h6">{{ getUser.name  }}</v-list-item-title>
                                <v-list-item-title class="text-caption">{{ getUser.email }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-row class="py-2 px-4">
                            <v-col class="d-flex align-center">
                                <v-icon small color="primary" class="mr-2">mdi-clock</v-icon>
                                <span class="text-caption">{{ `${tokenExp()}` }}</span>
                                <v-spacer></v-spacer>
                                <v-btn x-small text color="primary"
                                    @click="renewSession()"
                                >
                                    Renovar
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-list-item @click="endSession()">
                            <v-list-item-content>
                                <v-list-item-title>Sair</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-sheet>
        </v-menu>

        <v-divider
                vertical
                class="mr-3 ml-3"
                inset
            ></v-divider>

            <!-- <SettingsMenu></SettingsMenu> -->

            <a href="https://znap.com.br/" target="_blank"
                style="text-decoration: none;"
                class="d-flex align-center"
            >
                <!-- <v-img
                    max-width="120"
                    src="/img/znap-app-bar.png"
                >
                </v-img> -->
                <img
                    src="/img/znap-app-bar.png"
                    alt="Logotipo da Znap Technologies"
                    style="max-width: 120px;"
                >
            </a>
    </v-app-bar>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import SettingsMenu from '@/components/SettingsMenu.vue'

export default {
    name: 'AppBar',

    // components: { SettingsMenu },

    data() {
        return {
            menuItems: [
                { text: this.$vuetify.lang.t('$vuetify.logout'), link: '/' },
            ],

            // session exp
            now: new Date(),
            sessionBadgeColor: 'success',
        }
    },
    
    computed: {
        ...mapGetters('theme', ['getLogos']),
        ...mapGetters('auth', ['getTokenExp', 'getUser', 'getHash', 'getEnableSessionExpiredDialog']),
    },

    mounted() {
        setInterval(() => {
            this.now = new Date()
        }, 1000)
    },

    beforeUnmount() {
        clearInterval()
    },

    methods: {
        ...mapMutations('auth', ['setSessionExpiredDialog', 'setInvalidHashDialog']),
        ...mapActions('auth', ['setHash', 'hashAuthenticate', 'logout']),

        checkUrl(){
            if(window.location.href.indexOf('/meeting-rooms') == -1) return true
            return false
        },

        endSession() {
            return this.logout()
        },

        tokenExp() {
            let tokenExp = ''

            if (this.getTokenExp && !this.isValidToken(this.getTokenExp)) {
                if (this.getEnableSessionExpiredDialog) this.setSessionExpiredDialog(true)
                this.setSessionBadgeColor(hours, minutes)
                return tokenExp = 'Sessão expirada'
            }

            let now = moment(this.now, 'hh:mm:ss')
            let exp = moment.unix(this.getTokenExp, 'hh:mm:ss')
            let duration = moment.duration(exp.diff(now))._data

            let hours = duration.hours.toString().length < 2 ? `0${duration.hours}` : `${duration.hours}`
            let minutes = duration.minutes.toString().length < 2 ? `0${duration.minutes}` : `${duration.minutes}`
            let seconds = duration.seconds.toString().length < 2 ? `0${duration.seconds}` : `${duration.seconds}`
            let time = `${hours}:${minutes}:${seconds}`

            this.setSessionBadgeColor(hours, minutes)

            tokenExp = `Sessão expira em ${time}`

            let timeCompare = time.replaceAll(':', '')
            timeCompare = parseInt(timeCompare, 10)
            if (duration.hours < 1 && duration.minutes < 5) {
                if (timeCompare > 1 && this.renewLoading) {
                    this.renewLoading = false
                    this.renewSession().then(() => {
                        if (duration.hours < 1 && duration.minutes < 5) {
                            this.renewLoading = true
                        }
                    })
                }
            }

            return tokenExp
        },

        setSessionBadgeColor(hours, minutes) {
            if (!hours || !minutes) {
                return this.sessionBadgeColor = 'error'
            }

            let h = parseInt(hours)
            let m = parseInt(minutes)

            if (h === 0) {
                if (m >= 10) {
                    this.sessionBadgeColor = 'success'
                } else if (m < 10 && m > 5 ) {
                    this.sessionBadgeColor = 'warning'
                } else if (m < 5) {
                    this.sessionBadgeColor = 'error'
                }
            } else {
                this.sessionBadgeColor = 'success'
            }
        },

        isValidToken(tokenExp) {
			let now = Math.floor(Date.now() / 1000)
            return now - tokenExp <= 0
		},

        async renewSession() {
            let hash = this.getHash

			const auth = await this.hashAuthenticate(hash)
            if (auth === 'success') {
                this.$toast.success('Sessão renovada com sucesso')
            } else {
                let err = auth
                this.$fnError(err)
            }
        },

        openMeetingRoom() {
            return window.open('http://127.0.0.1:5500/Znap-Portal/src/plugins/jitsi/index.html')
        },
    },
}
</script>

<style>

</style>