<template>
    <div id="modules" class="view">
		<AppBar
			@logout="logout" 
			@setMeetingRoomsDialog="meetingRoomsDialog = $event"
		/>

		<v-container fluid class="mt-6 mb-12" style="height: calc(100vh - 136px);"
			:class="{ 'd-flex' : isLoadingAuth}"
		>
			<v-row v-if="isLoadingAuth" class="justify-center align-center">
				<v-col class="d-flex justify-center">
					<v-card elevation="6"
						class="d-flex flex-column justify-center align-center py-8 px-4 mb-8"
						min-width="300px" max-width="300px"
						min-height="200px" max-height="200px"
					>
						<v-progress-circular
							indeterminate
							color="primary"
						></v-progress-circular>
						<p class="text-caption ma-0 mt-4">Carregando...</p>
					</v-card>
				</v-col>
			</v-row>
			
			<v-row v-else class="d-flex justify-center">
				<v-col data-testid="portal-module" xl="3" lg="3" v-for="module in getModules" :key="module.id_module" class="d-flex justify-center">
					<v-hover v-slot="{ hover }"
						close-delay="75"
						open-delay="75"
					>
						<a class="text-decoration-none"
							:href="navigate(module.subdomain)"
						>
							<v-card elevation="6"
								class="d-flex flex-column justify-center align-center py-8 px-4 mb-8"
								min-width="300px"
								max-width="300px"
							>
								<div class="primary d-flex justify-center align-center rounded-circle">
									<v-icon x-large dark class="pa-4">
										{{ module.icon }}
									</v-icon>
								</div>
								<p class="ma-0 mt-6 mb-4 text-h6">{{ module.description }}</p>
								<v-expand-transition>
									<div v-if="hover || $vuetify.breakpoint.smAndDown"
										class="d-flex justify-center align-center mx-8"
									>
										<p class="text-center text-caption ma-0">{{ module.subtitle }}</p>
									</div>
								</v-expand-transition>
							</v-card>
						</a>
					</v-hover>
				</v-col>
			</v-row>
		</v-container>

		<MeetingRoomsDialog
			:meetingRoomsDialog="meetingRoomsDialog"
			@setMeetingRoomsDialog="meetingRoomsDialog = $event" 
		/>
		
		<SessionExpiredDialog />
		<HelpDialog />
		<IssueDialog />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import AppBar from '@/components/AppBar.vue'
import getModulePort from '@/helpers/getModulePort'
import SessionExpiredDialog from '@/components/SessionExpiredDialog'
import MeetingRoomsDialog from '@/components/MeetingRoomsDialog'
import HelpDialog from '@/components/HelpDialog'
import IssueDialog from '@/components/IssueDialog.vue'
import configs from '@/configs'

export default {
    name: 'Modules',

	components: {
		AppBar, SessionExpiredDialog, MeetingRoomsDialog, HelpDialog, IssueDialog
	},

    data() {
		return {
			env: null,
			meetingRoomsDialog: false,
		}
	},

	computed: {
		...mapGetters('auth', ['getHash', 'getModules', 'isLoadingAuth'])
	},

    async created() {
		this.env = this.setEnv()
    },

    methods: {
		...mapMutations('auth', ['resetState', 'setEnableSessionExpiredDialog']),

		setEnv() {
			if (window.location.href.indexOf(':80') == -1)	{
				return 'prod'
			} else {
				return 'local'
			}
		},

		setUrl(subdomain) {
			let system = this.$store.getters['getSystem'].system.toLowerCase()
			let url = null

			if (this.env == 'prod') {
				url = `https://${system}${configs.env === 'homol' ? '.homol' : ''}${subdomain}/?token=${this.getHash}`
			} else {
				subdomain = subdomain.replace('.com','')
				let port = getModulePort(subdomain.split('.')[1])
				url = `http://${system}${subdomain}:${port}/?token=${this.getHash}`
			}

			return url
		},

        navigate(subdomain) {
			let url = this.setUrl(subdomain)
			return url
        },

		logout() {
            this.resetState()

            let url = null
            let system = this.$store.state.system.system

            if (window.location.href.indexOf(':80') == -1)	{
                url = 'https://' + system + configs.env === 'homol' ? '.homol' : '' +  '.znaptech.com'
            } else {
                url = 'http://' + system + '.znaptech:8080/'
            }

            window.location.href = url
        }
    }
}
</script>

<style>
/* #modules {
	height: 100%;
	font-family: 'Panton';
	overflow: auto;
}

body {
	width: 100vw;
	background-color: #272626;
	margin: 0;
}

.content {
	width: 100%;
	padding: 0 4%;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 767px) {
	.content {
		padding-top: 300px;
		flex-direction: column;
	}
}

.card {
	width: 100%;
	width: 260px;
	min-width: 200px;
	height: 250px;
	
	margin: 10px;
	border-radius: 10px;

	font-size: 16px;
	transition: all 0.3s ease;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	transition: all 0.3s ease;
	text-align: center;
}

.icon {
	margin: 0 auto;
	width: 100%;
	height: 80px;
	max-width: 80px;
	border-radius: 50% !important;
	align-items: center;
	color: white;
	transition: all 0.8s ease;
	background-position: 0px;
	background-size: 200px;
	padding-top: 20px;
}

.card .module-name {
	width: 100%;
	margin: 0;
	text-align: center;
	margin-top: 30px;

	font-weight: 600;
	letter-spacing: 4px;
}

.card .text {
	width: 80%;
	margin: 0 auto;
	font-size: 13px;
	text-align: center;
	margin-top: 20px;
	
	font-weight: 200;
	letter-spacing: 2px;
	opacity: 0;
	max-height: 0;
	transition: all 0.3s ease;
}

.card:hover {
	height: 270px;
}

.card:hover .info {
	height: 90%;
}

.card:hover .text {
	transition: all 0.3s ease;
	opacity: 1;
	max-height: 40px;
}

.overlay{
    position: relative;
    z-index: 10;
}
.context {
    width: 100%;
    position: absolute;
    top:50vh;
    
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
} */
</style>